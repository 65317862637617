import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import BackgroundImage, { IFluidObject } from 'gatsby-background-image';
import { Link } from 'gatsby-plugin-react-i18next';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import { EmailIcon } from './EmailIcon';
import { WebIcon } from './WeblIcon';

type Props = {
  bgImage: IFluidObject
};

function Footer({ bgImage }: Props) {
  const imageData = [
    'linear-gradient(rgba(0, 33, 65, 0.9), rgba(0, 18, 35, 0.9))',
    bgImage,
  ];
  return (
    <BackgroundImage
      Tag="footer"
      fluid={imageData}
    >
      <Container
        fluid="lg"
        id="contact"
        className="py-5 text-white"
      >
        <Row>
          <Col
            xs="12"
            md="6"
            className="d-flex justify-content-center align-items-center"
          >
            <div className="text-center text-md-start">
              <div className="mb-3">
                <span className="d-block mb-1">
                  <span
                    className="me-2"
                    style={{ fontSize: '1.5rem' }}
                  >
                    &#8962;
                  </span>
                  MASTER-ERP.RU
                </span>
                <span className="d-block" />
              </div>
              <div className="mb-3">
                <OutboundLink
                  className="text-decoration-none"
                  href="tel:+79046068447"
                >
                  +7 904 606-84-47
                </OutboundLink>
                ,&nbsp;
                <OutboundLink
                  className="text-decoration-none"
                  href="tel:+79500281043"
                >
                  +7 950 028-10-43
                </OutboundLink>
                ,&nbsp;
                <OutboundLink
                  className="text-decoration-none"
                  href="tel:+79819171204"
                >
                  +7 981 917-12-04
                </OutboundLink>
              </div>
              <div>
                <span className="me-2">
                  <EmailIcon
                    fill="white"
                    style={{ height: '1rem' }}
                  />
                </span>
                <OutboundLink
                  className="text-decoration-none"
                  href="mailto:info@master-erp.ru"
                >
                  info[@]master-erp.ru
                </OutboundLink>
              </div>
              <div>
                <span className="me-2">
                  <WebIcon
                    fill="white"
                    style={{ height: '1rem' }}
                  />
                </span>
                <Link
                  className="text-decoration-none"
                  to="https://www.master-erp.ru"
                >
                  www.master-erp.ru
                </Link>
              </div>
            </div>
          </Col>
          <Col
            xs="12"
            md="6"
            className="d-flex justify-content-center align-items-center mt-5 mt-md-0"
          >
            <div>
              &copy; 2023 MASTER-ERP.RU
              <br />
              All rights reserved
            </div>
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
}

export {
  Footer,
};
