import React from 'react';
import { Helmet } from 'react-helmet';

function Seo() {
  const seo = {
    title: 'master-erp.ru',
    description: 'Разработка программного обеспечения, создание перспективных программных продуктов, \n'
        + 'внедрение программного обеспечения на производстве, сопровождение на протяжении всего жизненного цикла программного обеспечения, ЕРП, ERP, КИС, МРП MRP, ЦРМ CRM, SCM, ВМС WMS, цифровизация процессов производства, дигитализация процессов производства, цифровая трансформация производственного предприятия, дигитальная трансформация производственного предприятия, малое производственное предприятие, среднее производственное предприятие, цифровизация цепочек поставок, автоматизация информационных потоков и документооборота, интеграция производственного оборудования с корпоративной информационной системой, система управления производством, автоматизация управления производством.',
    url: 'https://master-erp.ru',
  };

  return (
    <Helmet
      title={seo.title}
      link={[
        { rel: 'icon', type: 'image/svg', href: '/favicon-master-erp-ru.svg' },
      ]}
    >
      <meta
        name="description"
        content={seo.description}
      />
      {seo.url && (
        <meta
          property="og:url"
          content={seo.url}
        />
      )}
      {seo.title && (
        <meta
          property="og:title"
          content={seo.title}
        />
      )}
      {seo.description && (
        <meta
          property="og:description"
          content={seo.description}
        />
      )}
      {seo.title && (
        <meta
          name="twitter:title"
          content={seo.title}
        />
      )}
      {seo.description && (
        <meta
          name="twitter:description"
          content={seo.description}
        />
      )}
    </Helmet>
  );
}

export {
  Seo,
};
